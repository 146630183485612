const Backendapi = {
  REACT_APP_BACKEND_API_URL: "",
  // REACT_APP_BACKEND_API_URL: "http://localhost:9011",
  REACT_APP_SuperUser_EMAIL: "areesh.kumar@p2fsemi.com", // Initial value as an empty string
  // LIVE_TIME_ZONE_CREATE_START_TIME: `.subtract(5, "hours")
  //         .subtract(30, "minutes").format()`,
  // LIVE_TIME_ZONE_CREATE_END_TIME: ` .subtract(5, "hours")
  //         .subtract(30, "minutes").format()`,
  // LIVE_TIME_ZONE_UPDATE_START_TIME: `.format("YYYY-MM-DDTHH:mm")`,
  // LIVE_TIME_ZONE_UPDATE_END_TIME: `.format("YYYY-MM-DDTHH:mm")`

  //for LOCAL :
  LIVE_TIME_ZONE_CREATE_START_TIME: `.format()`,
  LIVE_TIME_ZONE_CREATE_END_TIME: `.format()`,
  LIVE_TIME_ZONE_UPDATE_START_TIME: `.add(5, "hours")
.add(30, "minutes").format("YYYY-MM-DDTHH:mm")`,
  LIVE_TIME_ZONE_UPDATE_END_TIME: `.add(5, "hours")
.add(30, "minutes").format("YYYY-MM-DDTHH:mm")`

};
export default Backendapi;

// .add(5, "hours")
// .add(30, "minutes")
// .format("YYYY-MM-DDTHH:mm")
// .add(5, "hours")
// .add(30, "minutes")
// .format("YYYY-MM-DDTHH:mm")
//http://13.232.248.126 bhavya@p2fsemi.com vasikaran@p2fsemi.in areesh.kumar@p2fsemi.com
