import React from 'react'

function Success() {
  return (
    <div>
      <h1 className='text-blue  font-xl ' >Welcome Super User</h1>
    </div>
  )
}

export default Success




